import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi } from '../ContractABI'; // Adjust the path as needed
import '../styles/ConnectButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

const contractAddress = '0xF1bf9d930cB2c63059155B05b294545503797964';

// Web3Modal configuration
const projectId = 'd1c2210ee614f7c716a2e89e2aa68b50'; // Replace with your WalletConnect project ID
const infuraUrl = 'https://goerli.infura.io/v3/15083069ebbe40b6947abaabe8a27821'; // Infura URL for Goerli
const bscMainnet = {
  chainId: '0x38', // Hexadecimal for 56
  name: 'BSC Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/' // BSC mainnet RPC URL
};
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
};

const provider = new ethers.providers.JsonRpcProvider(bscMainnet.rpcUrl);
const contract = new ethers.Contract(contractAddress, abi, provider);

export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId: '' // No projectId needed for BSC mainnet
  });


  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);

  const switchToBsc = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: bscMainnet.chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: bscMainnet.chainId,
                rpcUrl: bscMainnet.rpcUrl,
                // Add any other relevant parameters here
              },
            ],
          });
        } catch (addError) {
          console.error('Error adding BSC network:', addError);
        }
      }
    }
  };

  useEffect(() => {
     const fetchWalletDetails = async () => {
      if (signer) {
        try {
          const network = await signer.getChainId();
          if (network !== parseInt(bscMainnet.chainId, 16)) {
            await switchToBsc();
            return;
          }
          const address = await signer.getAddress();
          const balance = await signer.getBalance();
          setCurrentUserAddress(address);
          const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
          setWalletBalance(etherBalance);
                } catch (error) {
          console.error('Error fetching wallet details:', error);
        }
      }
    };

    fetchWalletDetails();
  }, [signer]);

  const isWalletConnected = () => {
    return currentUserAddress !== '';
  };

  

  return (
    
    <div>
    {isWalletConnected() ? (
      <div className="dropdown">
        <button className="dropbtn" onClick={() => open()}>
          <FontAwesomeIcon icon={faWallet} />
            {` ${currentUserAddress.substring(0, 3)}...${currentUserAddress.substring(currentUserAddress.length - 4)}`}
        </button>
        <div className="dropdown-content">
        <span className="wallet-address-display">
            Wallet connected: {`${currentUserAddress.substring(0, 6)}...${currentUserAddress.substring(currentUserAddress.length - 4)}`}
          </span>
          <p>Balance: {walletBalance} BNB</p>
        </div>
      </div>
    ) : (
      <button className="connect-wallet-button" onClick={() => open()}>
        Connect Wallet
      </button>
    )}
  </div>
  
  );
}


