import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import LotteryPage from './pages/LotteryPage';
import { WalletProvider } from './contexts/WalletContext'; // Adjust the path based on your project structure
import ConnectButton from './components/ConnectButton'; // Import the ConnectButton component

const App = () => {
  return (
    <WalletProvider>

    <Router>
      <Routes>
        <Route path="/" element={<LotteryPage />} />

        {/* Otras rutas */}
      </Routes>
    </Router>

    </WalletProvider>
  );
};

export default App;


